<template>
    <div class="w-100">
        <!-- Header @start -->
        <Header />
        <!-- Header @end -->

        <!-- Back Button @start-->
        <b-row class="w-100 mt-6 px-3">
            <b-col cols="12" class="mb-3 zc-text-format">
                <!-- arrow-left.svg -->
                <div style="width: fit-content;" class="go-back">
                    <img src="@/assets/zaajira-candidate/assets/icons/arrow-left.svg" width="19px" height="19px"
                        class="img-fluid" />
                    <span style="font-size: 1.2rem;" class="pl-3"> Notification</span>
                </div>
            </b-col>
        </b-row>
        <!-- Back Button @end-->



        <!-- Notification @start -->
        <b-row style="padding-top: 0 !important;" class="p-3 m-0 ">
            <!-- Related jobs @start -->
            <b-col cols="12" col lg="12" md="12" sm="12" class="zc-container">
                <div v-for="item in 15 " :key="item" class="my-2">
                    <div class="d-flex justify-content-between py-3" style="border-bottom: 1px solid #F5F5F7;">
                        <div class="d-flex">
                            <b-avatar style="background: #F1F1F1;" class="mr-2">
                                <img src="@/assets/zaajira-candidate/assets/company-logo/amazon.svg" width="75%"
                                    height="auto" class="img-fluid" />
                            </b-avatar>
                            <div style="padding: 0 0 0 0; margin-top: -4px;" cols="8">
                                <span style="font-size:1rem ;" class="h-job-name">New Message</span>
                                <div style="gap: 10px;" class="d-flex">
                                    <span
                                        style="font-size: 0.875rem; font-weight:500;color: var(--neutral-dark-grey, #9C9CA4);"
                                        class="h-company"> Apple replayed to your message
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-end justify-content-between">
                            <!-- <span class="chat-time">{{ convertToTimeSince(m.created_at) }} ago</span> -->
                            <div class="chat-time" style="color: var(--neutral-dark-grey, #9C9CA4);">2 m ago</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#F90" />
                            </svg>
                        </div>
                    </div>
                    <!-- <img src="@/assets/zaajira-candidate/assets/icons/divider.svg" width="100%" height="auto"
                        class="img-fluid mt-2" /> -->
                </div>

            </b-col>
        </b-row>
        <!-- Notification @end -->
    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
export default {
    name: "Notification",
    components: { Header }

}
</script>

<style></style>